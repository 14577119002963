import React, { createContext, useContext } from "react";
import useOktaIdentity from "../hooks/oktaHook";

interface AuthenticationState {
  whoami: Object | null;
  accessToken: string | null;
  isAuthenticating: boolean;
}

const defaultState: AuthenticationState = {
  whoami: null,
  accessToken: null,
  isAuthenticating: false,
};

const AuthContext = createContext(defaultState);

export const useAuthContext = () => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const {
    whoami,
    accessToken,
    refreshToken,
    isLoading,
    clearIdentity,
    logout,
    setAccessToken,
    setRefreshToken,
    impersonation,
    setImpersonation,
    clearImpersonation,
  } = useOktaIdentity();

  return (
    <AuthContext.Provider
      value={{
        whoami,
        accessToken,
        clearIdentity,
        isAuthenticating: isLoading,
        logout,
        refreshToken,
        setRefreshToken,
        setAccessToken,
        impersonation,
        setImpersonation,
        clearImpersonation,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
