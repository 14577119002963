import React, { useState } from "react";
import { AuthProvider } from "../../contexts/authContext";

const Wrapper = (props) => {
  return (
    <AuthProvider>
      <div>{props.children}</div>
    </AuthProvider>
  );
};

export default Wrapper;
