import { useState, useCallback } from "react";

export default function useLocalStorage(name: string) {
  const [value, setValue] = useState(() => {
    const entry = localStorage.getItem(name);

    if (entry) return entry;
  });

  const updateLocalStorage = useCallback((newValue: string) => {
    if (newValue != null) {
      localStorage.setItem(name, newValue);
    }

    setValue(newValue);
  });

  const removeLocalStorage = useCallback(() => {
    localStorage.removeItem(name);
    setValue(null);
  });

  return [value, updateLocalStorage, removeLocalStorage];
}
